<template>
 <base-main-container>
    <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="68">
          AI is preparing your resource
        </v-progress-circular>
      </v-overlay>

       <template v-slot:centered>
      <v-card class="my-card rounded-lg" elevation="0">
        <v-card-title>EduScript: Subject-Centric Short Play Creator for Educators 
        
        </v-card-title>
        <v-card-text>

         
          <v-text-field
            
            v-model="topic"
            class="ml-2"
            label="Topic/Subject"
            outlined
          />
          <v-select
           
            class="mt-2 ml-2"
            append-icon="mdi-filter"
            v-model="grade"
            :items="[
              'K',
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
            ]"
            label="Suitable for Grade level"
            outlined
            clearable
          />

         
          <v-combobox
           
            class="mt-2 ml-2"
            append-icon="mdi-filter"
            v-model="tone"
            :items="['Funny', 'Friendly', 'Cold', 'Neutral']"
            label="Tone"
            outlined
            clearable
             @input.native="tone=$event.srcElement.value"
          />
         

          <v-text-field
           
            v-model="genre"
            label="(Optional) Genre"
          />
          <v-text-field
           
            v-model="characters"
            label="(Optional) Character names, i.e. John, Eve and Mr.Brown "
          />
          <v-text-field
            
            v-model="voice"
            label="(Optional) In the voice of , i.e. Mark Twain, Homer Simpson etc.)"
          />
          
        </v-card-text>
        <v-card-actions>
          <v-spacer />
 <v-btn class="mr-2" outlined link to="newresource">
              <v-icon class="mr-2"> mdi-backspace</v-icon> Cancel
            </v-btn>
          <v-btn
            :disabled="!((topic))
            "
            color="#212121"
            @click="sendRequest()"
          >
            <h3
              v-if="
               !((topic))
              "
            >
              Generate please
            </h3>
            <h3 v-else style="color: white">Generate please</h3>
          </v-btn>

          <v-spacer />
        </v-card-actions>
      </v-card>
       </template>
   <v-snackbar
          v-model="showSnackbar"
          :timeout="6000"
           color="warning"
          top
          
        >
          {{ snackbarMessege }}
        </v-snackbar>
  </base-main-container>
</template>

<script>

import { getFunctions, httpsCallable } from "firebase/functions";

import { firebaseAuth } from "../../firebase";
import { db } from "../../firebase";

export default {
 
  data() {
    return {
      what: "Short Play Script",
      topic: null,
      grade: null,
      genre: null,
      tone: null,
      characters: null,
      voice: null, 
     // receivedPlan: "",
      isLoading: false,
     
      showSnackbar:false,
      snackbarMessege:"",
      
    };
  },
  // computed:{
  //  premium(){
  //     return this.$store.state.subscription ? this.$store.state.subscription.status =='active' 
  //     || this.$store.state.subscription.status == 'active school'
  //      || this.$store.state.subscription.status == 'trialing'
  //     : false
  //   }
  // },

  methods: {
  
    async sendRequest() {
      this.isLoading = true;
     
     
      let character = this.characters
        ? "please use these characters: " + this.characters + ", "
        : "you may choose the characters";
      let genre = this.genre ? "yes, please write in this genre: " + this.genre + ", " : "No specific genre";
      let voice = this.voice ? "from voice " + this.voice + ", " : "no, i dont have a preference";
      let tone = this.tone ? "use " + this.tone + " tone" + ", " : "no specific tone";
    
      
let uid = firebaseAuth.currentUser.uid;
let newresource ={
    what: this.what,
        topic: this.topic,
        grade: this.grade,
        lastsaved: Date.now(), 
        promt: [
    { "role": "system", "content": "You are an excellent educator, teacher and play script writer." },
    { "role": "user", "content": "I need a Short Play Script in a specific topic for my students" },
    { "role": "assistant", "content":"What is the topic of the play or what you would like to teach with this play"},
    { "role": "user", "content": this.topic },
    { "role": "assistant", "content":"Thank you. What is your students grade level. I can adjust the language and vocabulary level based on the students age and grade level"},
    { "role": "user", "content": this.grade },  
    { "role": "assistant", "content":"Thank you. What/who characters you would like to see in this play"},
    { "role": "user", "content": character },
    { "role": "assistant", "content":"Do you have a specific genre for the play"},
    { "role": "user", "content": genre },
    { "role": "assistant", "content":"Thank you. I can write play scripts from the voice of famuous people and writer, would you like me to use any specif famous people voice"},
    { "role": "user", "content": voice },
    { "role": "assistant", "content":"thank you. Lastly, would you like me to write in a specific tone?"},
    { "role": "user", "content": tone},
    { "role": "assistant", "content":"thank you, I have all I need to create a story for your students"},
    { "role": "user", "content": "Please write a good Short Play Script for my students whit all the provided context" }  
],
        uid:uid    
}
try {
          const userRef = db.collection("customers").doc(uid);
          const subcollectionRef = userRef.collection("saved");

          const docRef = await subcollectionRef.add(newresource)
          //console.log(docRef.id);
          newresource.id=docRef.id
        } catch (error) {
          console.error("Error adding document:", error);
        }

const functions = getFunctions();

      try { 
      const functionRef = httpsCallable(
        functions,
        "callopenai"
      );
      functionRef(newresource);
       
        this.isLoading = false;
        this.saveDialog = false;
        this.$router.push("/mydocs");

}catch (error) {
      this.isLoading = false;
      this.showSnackbar = true
      this.snackbarMessege = "The system is overloaded, please try again" 
}

    },

    clearSelections() {
      (this.topic = null),
        (this.grade = null),
        (this.genre = null),
        (this.tone = null),
        (this.characters = null),
        (this.voice = null)
    },
     
  },
  
};
</script>

<style scoped>

    .centered {
        border-radius: 15px;  
        background-color:#f3f6fc; 
        display: flex;
        justify-content: center;
        max-width: 1200px;
        height: 90vh; /* Adjust this value to match your desired height */
       
    }
    @media (min-width: 600px) {
  .my-card {
  
    width: 300px;
  }
}
@media (min-width: 960px) {
  .my-card {
   
    width: 500px;
  }
}
@media (min-width: 1264px) {
  .my-card {
   
    width: 800px;
    
  }
}
    .container.fill-height {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}


</style>




